document.addEventListener('click', (e) => {
  // Only handle clicks on local links
  const anchor = e.target.closest('a')
  if (!anchor || !anchor.href.startsWith(window.location.origin)) {
    return
  }

  // Prevent default navigation
  e.preventDefault()

  // Start view transition
  if (!document.startViewTransition) {
    window.location = anchor.href
    return
  }

  document.startViewTransition(async () => {
    try {
      // Navigate to the new page
      const response = await fetch(anchor.href)
      const text = await response.text()

      // Extract the main content from the new page
      const parser = new DOMParser()
      const newDoc = parser.parseFromString(text, 'text/html')

      // scroll to top
      window.scrollTo(0, 0)

      // Update the document content
      document.documentElement.replaceChildren(
        ...newDoc.documentElement.childNodes
      )

      // Update the URL
      window.history.pushState({}, '', anchor.href)
    } catch (error) {
      // Fallback to normal navigation if something goes wrong
      window.location = anchor.href
    }
  })
})

// Handle back/forward navigation
window.addEventListener('popstate', async (e) => {
  if (!document.startViewTransition) {
    window.location.reload()
    return
  }

  document.startViewTransition(async () => {
    try {
      // Fetch the page content for the current URL
      const response = await fetch(window.location.href)
      const text = await response.text()

      // Parse the new content
      const parser = new DOMParser()
      const newDoc = parser.parseFromString(text, 'text/html')

      // scroll to top
      window.scrollTo(0, 0)

      // Update the document content
      document.documentElement.replaceChildren(
        ...newDoc.documentElement.childNodes
      )
    } catch (error) {
      window.location.reload()
    }
  })
})
